<template>
  <div id="resetPassword">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('resetPassword.header') }}</h2>
          <p></p>
        </div>
        <div class="resetPassword_box" v-if="formFlag">
          <div class="reset_form" v-show="isAuthorized && formFlag">
            <el-form :model="ruleResetPasswordForm" :rules="rulesResetPassword" ref="ruleResetPasswordForm">
              <InputForm
                :label="$t('common.field.newPw')"
                name="pass"
                v-model.trim="ruleResetPasswordForm.pass"
                type="password"
              ></InputForm>
              <InputForm
                :label="$t('common.field.confirmPw')"
                name="checkPass"
                v-model.trim="ruleResetPasswordForm.checkPass"
                type="password"
              ></InputForm>
              <div class="btn_item">
                <el-button
                  class="purple_button"
                  @click.native="submitForm('ruleResetPasswordForm')"
                  data-testid="submit"
                >
                  {{ $t('common.button.submit') }}
                </el-button>
              </div>
            </el-form>
          </div>
          <p v-show="isNotAuthroized" class="no_authroization">{{ $t('resetPassword.failed') }}</p>
        </div>
        <Result v-if="successFlag">
          <p>{{ $t('resetPassword.succ2') }}</p>
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import { md5 } from '@/util/encrypt';
import { encrypt } from '@/util/rsa/JSEncrypt.js';
import {
  apiCheckResetPasswordAuthorization,
  apiSubmitResetProfilePassword,
  apiEncryptPublicKey
} from '@/resource';
import Result from '@/components/Result';

export default {
  name: 'resetProfilePassword',
  components: {
    Result
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwFormat')));
        }
      }
      callback();
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleResetPasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      schema: '',
      rulesResetPassword: {
        pass: [{ required: true, validator: validatePass, trigger: 'blur' }],
        checkPass: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      }
    };
  },
  mounted() {
    const token = this.$route.query.token;
    this.verifyAuthorization(token);
  },
  methods: {
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: this.$route.query.token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },
    async submitResetProfilePassword() {
      let result = await apiEncryptPublicKey(this.ruleResetPasswordForm.token);
      let publicKey = result.data.data;
      apiSubmitResetProfilePassword(
        {
          token: this.ruleResetPasswordForm.token,
          pass: encrypt(this.ruleResetPasswordForm.pass, publicKey),
          checkPass: encrypt(this.ruleResetPasswordForm.checkPass, publicKey)
        },
        publicKey
      ).then(resp => {
        if (resp.data.code == 0 && resp.data.data.result == true) {
          this.formFlag = false;
          this.successFlag = true;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submitResetProfilePassword();
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/resetProfilePassword.scss';
</style>
